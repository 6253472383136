@font-face {
  font-family: NNDagnyText;
  src: url("./NNDagnyTextWeb.woff");
}

@font-face {
  font-family: NNDagnyDisplay;
  src: url("./NNDagnyDisplayWebPro.woff");
}

@font-face {
  font-family: DisplayScWebPro;
  src: url("./NNDagnyDisplayScWebPro.woff");
}

* {
  font-family: NNDagnyText,sans-serif;
}
