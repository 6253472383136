* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

#root {
  min-height: 100vh;
}
